<template>
  <button @click.prevent="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)" class="p0 inline-flex middle-xs bg-cl-transparent brdr-none h5 pointer" aria-label="Add to wishlist" type="button" data-testid="addToWishlist">
    <slot>
      <div class="flex middle-xs">
        <i class="icon-size" :class="isOnWishlist ? 'icon-heart_contour cl-main border-mobile-active': 'icon-heart_contour cl-black border-mobile'" />
        <span class="hidden-md cl-black ml10">{{ isOnWishlist ? 'Usuń ze schowka' : 'Dodaj do schowka' }}</span>
      </div>
    </slot>
  </button>
</template>

<script>
import { IsOnWishlist } from 'src/modules/vsf-magento-wishlist/components/IsOnWishlist'
import { AddToWishlist } from 'src/modules/vsf-magento-wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from 'src/modules/vsf-magento-wishlist/components/RemoveFromWishlist'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'

export default {
  mixins: [ IsOnWishlist, AddToWishlist, RemoveFromWishlist ],
  methods: {
    addProductToWhishlist (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been added to wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') },
        action2: { label: i18n.t('Go to wishlist'), action: 'toWishlist' }
      }, { root: true })
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.border-mobile-active {
  @media (max-width: 767px)  {
    border: 2px solid $main;
    padding: 12px;
    border-radius: 50%;
  }
}
.border-mobile {
  @media (max-width: 767px)  {
    border: 1px solid;
    padding: 12px;
    border-radius: 50%;
  }
}
</style>
